<template>
<div>
  <confirmation ref="Confirmation"></confirmation>
  <datatable-heading
    :title="$t('menu.roles')"
    :changePageSize="changePageSize"
    :searchChange="_.debounce(searchChange, 500)"
    :from="from"
    :to="to"
    :total="total"
    :perPage="perPage"
    :tableFields="fields"
    @filterChange="filterChange">
  </datatable-heading>

  <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
    <b-row>
      <b-colxx xxs="12">
        <b-card>
          <vuetable
            ref="vuetable"
            :api-url="apiBase"
            :query-params="makeQueryParams"
            :per-page="perPage"
            :reactive-api-url="false"
            :fields="fields"
            :css="css.table"
            pagination-path
            :row-class="onRowClass"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:row-clicked="rowClicked"
            @vuetable:cell-rightclicked="rightClicked"
            @vuetable:load-error="handleLoadError"
            @vuetable:cell-clicked="cellclicked"
            @vuetable:loading="onVuetableLoading"
            @vuetable:loaded="onVuetableLoaded"
          >
          <template slot="permissions" slot-scope="props">
              <ReadMoreList separator="," :linesToShow="2" :listText="props.rowData.permissions"/>
            </template>
          </vuetable>
        </b-card>
        <vuetable-pagination-bootstrap
          class="mt-4"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        />
      </b-colxx>
    </b-row>
  </b-overlay>
</div>
</template>
<script>
import readmoreCss from '../../../../assets/css/common/read-more.css'
import confirmation from '../../../../components/Common/Confirmation.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../../components/Common/VuetablePaginationBootstrap'
import { BeaconapiUrl } from '../../../../constants/config'
import DatatableHeading from '../../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../../constants/VuetableBootstrapconfig'
import { mapMutations } from 'vuex'
import ReadMoreList from '../../../../components/Listing/ReadMoreList.vue'
import moment from 'moment/moment.js'

export default {
  props: ['title'],
  components: {
    vuetable: Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Confirmation: confirmation,
    ReadMoreList: ReadMoreList
  },
  data () {
    var _idfield = 'roleID'
    return {
      tooltipDisabled: false,
      isLoad: false,
      apiBase: BeaconapiUrl + '/api/authorization/role-table',
      sort: '',
      page: 1,
      perPage: 50,
      search: '',
      filters: [],
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      fields: [
        {
          name: 'roleID',
          sortField: 'roleID',
          title: 'Role ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%',
          filterType: 'number'
        },
        {
          name: 'roleName',
          sortField: 'roleName',
          title: 'Role Name',
          dataClass: 'text-muted',
          width: '20%',
          filterType: 'text'
        },
        {
          name: 'roleDescription',
          sortField: 'roleDescription',
          title: 'Role Description',
          titleClass: '',
          dataClass: 'text-muted',
          width: '20%',
          filterType: 'text'
        },
        {
          name: '__slot:permissions',
          sortField: 'permissions',
          title: 'Permissions',
          titleClass: '',
          dataClass: 'text-muted',
          width: '25%',
          filterType: 'text'
        }
      ]
    }
  },
  methods: {
    ...mapMutations(['setDangerMessage', 'setAlertMessage', 'setInfoMessage', 'appendInfoMessage']),
    // Vue Table plumbing methods (standardized)
    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    filterChange (filters) {
      this.filters = filters
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected'
      }
      return outputclass
    },
    cellclicked (dataitem, field, event) {
    },
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]

      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    getIndex (value, arr, prop) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
          return i
        }
      }
      return -1
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    rowClicked (dataItem, event) {
      const itemId = dataItem[this.$data.idfield]

      if (this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = this.$refs.vuetable.selectedTo.filter(x => x !== itemId)
      } else {
        this.$refs.vuetable.selectedTo.push(itemId)
      }
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD').format('MMM Do, YYYY')
      }
    }

    // End of Standard Vue Table methods
  }
}
</script>
